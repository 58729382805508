export const actionsListServiceMock = {
  actions: [
    {
      id: 1,
      isRead: false,
      iconTooltip: {
        displayIcon: true,
        iconClass: 'pi pi-calendar',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      actionName: 'Audit to be confirmed',
      dateWithIcon: {
        displayIcon: true,
        iconClass: 'pi pi-info-circle',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      dueDate: '16.10.2024',
      message:
        'A new audit date is proposed, please confirm or request a reschedule',
      service: 'ISO 9001:2018',
      site: 'Opentech | Opentech',
      action: {
        actionType: 'redirect',
        iconClass: 'pi-arrow-right',
        label: 'angle-right',
        url: '',
      },
    },
    {
      id: 1,
      isRead: true,
      iconTooltip: {
        displayIcon: true,
        iconClass: 'pi pi-calendar',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      actionName: 'New NC',
      dateWithIcon: {
        displayIcon: true,
        iconClass: 'pi pi-info-circle',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      dueDate: '16.10.2024',
      message:
        'A new audit date is proposed, please confirm or request a reschedule',
      service: 'ISO 9001:2018',
      site: 'Opentech | Opentech',
      action: {
        actionType: 'redirect',
        iconClass: 'pi-arrow-right',
        label: 'angle-right',
        url: '',
      },
    },
    {
      id: 1,
      isRead: true,
      iconTooltip: {
        displayIcon: true,
        iconClass: 'pi pi-calendar',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      actionName: 'Confirm audit date',
      dateWithIcon: {
        displayIcon: true,
        iconClass: 'pi pi-info-circle',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      dueDate: '16.10.2024',
      message:
        'A new audit date is proposed, please confirm or request a reschedule',
      service: 'ISO 9001:2018',
      site: 'Opentech | Opentech',
      action: {
        actionType: 'redirect',
        iconClass: 'pi-arrow-right',
        label: 'angle-right',
        url: '',
      },
    },
    {
      id: 1,
      isRead: true,
      iconTooltip: {
        displayIcon: true,
        iconClass: 'pi pi-calendar',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      actionName: 'Confirm audit date',
      dateWithIcon: {
        displayIcon: true,
        iconClass: 'pi pi-info-circle',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      dueDate: '16.10.2024',
      message:
        'A new audit date is proposed, please confirm or request a reschedule',
      service: 'ISO 9001:2018',
      site: 'Opentech | Opentech',
      action: {
        actionType: 'redirect',
        iconClass: 'pi-arrow-right',
        label: 'angle-right',
        url: '',
      },
    },
    {
      id: 1,
      isRead: true,
      iconTooltip: {
        displayIcon: true,
        iconClass: 'pi pi-calendar',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      actionName: 'Confirm audit date',
      dateWithIcon: {
        displayIcon: true,
        iconClass: 'pi pi-info-circle',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      dueDate: '16.10.2024',
      message:
        'A new audit date is proposed, please confirm or request a reschedule',
      service: 'ISO 9001:2018',
      site: 'Opentech | Opentech',
      action: {
        actionType: 'redirect',
        iconClass: 'pi-arrow-right',
        label: 'angle-right',
        url: '',
      },
    },
    {
      id: 1,
      isRead: true,
      iconTooltip: {
        displayIcon: true,
        iconClass: 'pi pi-calendar',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      actionName: 'Confirm audit date',
      dateWithIcon: {
        displayIcon: true,
        iconClass: 'pi pi-info-circle',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      dueDate: '16.10.2024',
      message:
        'A new audit date is proposed, please confirm or request a reschedule',
      service: 'ISO 9001:2018',
      site: 'Opentech | Opentech',
      action: {
        actionType: 'redirect',
        iconClass: 'pi-arrow-right',
        label: 'angle-right',
        url: '',
      },
    },
    {
      id: 1,
      isRead: true,
      iconTooltip: {
        displayIcon: true,
        iconClass: 'pi pi-calendar',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      actionName: 'Confirm audit date',
      dateWithIcon: {
        displayIcon: true,
        iconClass: 'pi pi-info-circle',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      dueDate: '16.10.2024',
      message:
        'A new audit date is proposed, please confirm or request a reschedule',
      service: 'ISO 9001:2018',
      site: 'Opentech | Opentech',
      action: {
        actionType: 'redirect',
        iconClass: 'pi-arrow-right',
        label: 'angle-right',
        url: '',
      },
    },
    {
      id: 1,
      isRead: true,
      iconTooltip: {
        displayIcon: true,
        iconClass: 'pi pi-calendar',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      actionName: 'Confirm audit date',
      dateWithIcon: {
        displayIcon: true,
        iconClass: 'pi pi-info-circle',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      dueDate: '16.10.2024',
      message:
        'A new audit date is proposed, please confirm or request a reschedule',
      service: 'ISO 9001:2018',
      site: 'Opentech | Opentech',
      action: {
        actionType: 'redirect',
        iconClass: 'pi-arrow-right',
        label: 'angle-right',
        url: '',
      },
    },
    {
      id: 1,
      isRead: true,
      iconTooltip: {
        displayIcon: true,
        iconClass: 'pi pi-calendar',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      actionName: 'Confirm audit date',
      dateWithIcon: {
        displayIcon: true,
        iconClass: 'pi pi-info-circle',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      dueDate: '16.10.2024',
      message:
        'A new audit date is proposed, please confirm or request a reschedule',
      service: 'ISO 9001:2018',
      site: 'Opentech | Opentech',
      action: {
        actionType: 'redirect',
        iconClass: 'pi-arrow-right',
        label: 'angle-right',
        url: '',
      },
    },
    {
      id: 1,
      isRead: true,
      iconTooltip: {
        displayIcon: true,
        iconClass: 'pi pi-calendar',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      actionName: 'Confirm audit date',
      dateWithIcon: {
        displayIcon: true,
        iconClass: 'pi pi-info-circle',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      dueDate: '16.10.2024',
      message:
        'A new audit date is proposed, please confirm or request a reschedule',
      service: 'ISO 9001:2018',
      site: 'Opentech | Opentech',
      action: {
        actionType: 'redirect',
        iconClass: 'pi-arrow-right',
        label: 'angle-right',
        url: '',
      },
    },
    {
      id: 1,
      isRead: true,
      iconTooltip: {
        displayIcon: true,
        iconClass: 'pi pi-calendar',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      actionName: 'Confirm audit date',
      dateWithIcon: {
        displayIcon: true,
        iconClass: 'pi pi-info-circle',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      dueDate: '16.10.2024',
      message:
        'A new audit date is proposed, please confirm or request a reschedule',
      service: 'ISO 9001:2018',
      site: 'Opentech | Opentech',
      action: {
        actionType: 'redirect',
        iconClass: 'pi-arrow-right',
        label: 'angle-right',
        url: '',
      },
    },
    {
      id: 1,
      isRead: true,
      iconTooltip: {
        displayIcon: true,
        iconClass: 'pi pi-calendar',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      actionName: 'Confirm audit date',
      dateWithIcon: {
        displayIcon: true,
        iconClass: 'pi pi-info-circle',
        tooltipMessage: 'actions status',
        iconPosition: 'prefix',
      },
      dueDate: '16.10.2024',
      message:
        'A new audit date is proposed, please confirm or request a reschedule',
      service: 'ISO 9001:2018',
      site: 'Opentech | Opentech',
      action: {
        actionType: 'redirect',
        iconClass: 'pi-arrow-right',
        label: 'angle-right',
        url: '',
      },
    },
  ],
};
