import { Selector } from '@ngxs/store';

import { ActionsFilterModel } from '../../models/actions-filter.model';
import {
  ActionsFilterState,
  ActionsFilterStateModel,
} from '../actions-filter.state';

export class ActionsFilterSelectors {
  @Selector([ActionsFilterState])
  static NotificationFilterList(
    state: ActionsFilterStateModel,
  ): ActionsFilterModel[] {
    return state?.filterList || [];
  }
}
