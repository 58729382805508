import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { catchError, of, tap } from 'rxjs';

import { ActionsFilterModel } from '../models/actions-filter.model';
import { ActionsFilterService } from '../services';
import { LoadActionsFilterList } from './actions/actions-filter.action';

export interface ActionsFilterStateModel {
  filterList: ActionsFilterModel[];
  isPreferenceSet: boolean;
}

const defaultState: ActionsFilterStateModel = {
  filterList: [],
  isPreferenceSet: false,
};

@State<ActionsFilterStateModel>({
  name: 'actionsFilter',
  defaults: defaultState,
})
@Injectable()
export class ActionsFilterState {
  constructor(private actionsFilterService: ActionsFilterService) {}

  @Action(LoadActionsFilterList)
  loadNotificationFilterList(
    ctx: StateContext<ActionsFilterStateModel>,
    { params: _params }: LoadActionsFilterList,
  ) {
    return this.actionsFilterService.getActionsFilterList().pipe(
      tap((filterList) => {
        ctx.patchState({ filterList });
      }),
      catchError(() => {
        ctx.patchState({ filterList: [] });

        return of([]);
      }),
    );
  }
}
