import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';

import { ActionsListModel, ActionsModel } from '../models';
import { ActionsDetailsService } from '../services';
import {
  LoadActionsDetails,
  LoadActionsDetailsSuccess,
  MarkActionAsRead,
} from './actions';

export interface ActionsStateModel {
  actions: ActionsModel[];
}

const defaultState: ActionsStateModel = {
  actions: [
    {
      id: 1,
      isRead: false,
      iconTooltip: {
        displayIcon: false,
        iconClass: '',
        tooltipMessage: '',
        iconPosition: '',
      },
      actionName: '',
      dateWithIcon: {
        displayIcon: false,
        iconClass: '',
        tooltipMessage: '',
        iconPosition: '',
      },
      dueDate: '',
      message: '',
      service: '',
      site: '',
      action: {
        actionType: '',
        iconClass: '',
        label: '',
        url: '',
      },
    },
  ],
};

@State<ActionsStateModel>({
  name: 'actionsDetails',
  defaults: defaultState,
})
@Injectable()
export class ActionsState {
  constructor(private actionsDetailsService: ActionsDetailsService) {}

  // TODO - Need to correct it, once the API is ready.
  @Action(LoadActionsDetails)
  loadActionsDetails(ctx: StateContext<ActionsStateModel>): ActionsListModel {
    return this.actionsDetailsService
      .getActionsList('category', 'service', 'company', 'site')
      .pipe(
        tap((actionsList: ActionsModel[]) => {
          ctx.dispatch(new LoadActionsDetailsSuccess(actionsList));
        }),
      );
  }

  @Action(MarkActionAsRead)
  markActionAsRead(
    ctx: StateContext<ActionsStateModel>,
    action: MarkActionAsRead,
  ): void {
    const state = ctx.getState();
    this.actionsDetailsService.updateActions(action.id).pipe(
      tap((actionlist) => {
        ctx.patchState({
          ...state,
          actions: actionlist,
        });
      }),
    );
  }

  @Action(LoadActionsDetailsSuccess)
  loadActionsDetailsSuccess(
    ctx: StateContext<ActionsStateModel>,
    { actionsList }: LoadActionsDetailsSuccess,
  ) {
    ctx.patchState({ actions: actionsList });
  }
}
