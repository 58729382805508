import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { actionsListServiceMock } from '../../../__mocks__';
import { ActionsModel } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class UnreadActionsService {
  getUnreadActions(): Observable<ActionsModel[]> {
    // TODO, will Replace this mock data with your actual HTTP call
    const mockData: ActionsModel[] = actionsListServiceMock.actions.filter(
      (action) => action.isRead === false,
    );

    return of(mockData);
  }
}
