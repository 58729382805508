import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import {
  ActionsFilterModel,
  ActionsFilterParams,
} from '../../models/actions-filter.model';
import { LoadActionsFilterList } from '../actions/actions-filter.action';
import { ActionsFilterSelectors } from '../selectors/actions-filter.selectors';

@Injectable()
export class ActionsFilterStoreService {
  constructor(private store: Store) {}

  get actionsFilterList(): Signal<ActionsFilterModel[]> {
    return this.store.selectSignal(
      ActionsFilterSelectors.NotificationFilterList,
    );
  }

  @Dispatch()
  loadActionsFilterList = (params?: ActionsFilterParams) =>
    new LoadActionsFilterList(params);
}
