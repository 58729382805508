import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';

import { ActionsModel } from '../models';
import { UnreadActionsService } from '../services/data-services';
import { LoadUnreadActions } from './actions/unread-actions.action';

export interface UnreadActionsStateModel {
  actions: ActionsModel[];
}

const defaultState: UnreadActionsStateModel = {
  actions: [
    {
      id: 1,
      isRead: false,
      iconTooltip: {
        displayIcon: false,
        iconClass: '',
        tooltipMessage: '',
        iconPosition: '',
      },
      actionName: '',
      dateWithIcon: {
        displayIcon: false,
        iconClass: '',
        tooltipMessage: '',
        iconPosition: '',
      },
      dueDate: '',
      message: '',
      service: '',
      site: '',
      action: {
        actionType: '',
        iconClass: '',
        label: '',
        url: '',
      },
    },
  ],
};

@State<UnreadActionsStateModel>({
  name: 'unreadActions',
  defaults: defaultState,
})
@Injectable()
export class UnreadActionsState {
  constructor(private unreadActionsService: UnreadActionsService) {}

  @Action(LoadUnreadActions)
  unreadNotificationList(ctx: StateContext<UnreadActionsStateModel>) {
    return this.unreadActionsService.getUnreadActions().pipe(
      tap((actions: ActionsModel[]) => {
        ctx.patchState({ actions });
      }),
    );
  }
}
