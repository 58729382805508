import { Selector } from '@ngxs/store';

import {
  UnreadActionsState,
  UnreadActionsStateModel,
} from '../unread-actions.state';

export class UnreadActionsSelectors {
  @Selector([UnreadActionsState])
  static getUnreadActions(state: UnreadActionsStateModel): number {
    return state?.actions.length || 0;
  }
}
