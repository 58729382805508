import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { ActionsModel } from '../../models';
import { LoadActionsDetails, MarkActionAsRead } from '../actions';
import { ActionsDetailsSelectors } from '../selectors';

@Injectable()
export class ActionsDetailsStoreService {
  get actionsDetails(): Signal<ActionsModel[]> {
    return this.store.selectSignal(ActionsDetailsSelectors.actionsDetails);
  }

  get totalRecords(): Signal<number> {
    return this.store.selectSignal(
      ActionsDetailsSelectors.totalFilteredRecords,
    );
  }

  constructor(private store: Store) {}

  @Dispatch()
  loadActionsDetails = () => new LoadActionsDetails();

  @Dispatch()
  markActionAsRead = (id: number) => new MarkActionAsRead(id);
}
