import { ActionsModel } from '../../models';

export class LoadActionsDetails {
  static readonly type = '[Actions Details] Load Actions Details';
}

export class LoadActionsDetailsSuccess {
  static readonly type = '[Actions Details] Load Actions Details Success';

  constructor(public actionsList: ActionsModel[]) {}
}

export class MarkActionAsRead {
  static readonly type = '[Actions Details] Mark Action As Read';

  constructor(public id: number) {}
}
