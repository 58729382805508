import { Selector } from '@ngxs/store';

import { ActionsModel } from '../../models';
import { ActionsState, ActionsStateModel } from '../actions.state';

export class ActionsDetailsSelectors {
  @Selector([ActionsState])
  static actionsDetails(state: ActionsStateModel): ActionsModel[] {
    return state?.actions;
  }

  @Selector([ActionsState])
  static totalFilteredRecords(state: ActionsStateModel): number {
    return state?.actions?.length;
  }
}
