import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { ActionsModel } from '../../models';
import { LoadUnreadActions } from '../actions';
import { UnreadActionsSelectors } from '../selectors';

@Injectable()
export class UnreadActionsStoreService {
  constructor(private store: Store) {}

  get unreadActions(): Signal<number> {
    return this.store.selectSignal(UnreadActionsSelectors.getUnreadActions);
  }

  @Dispatch()
  loadUnreadActions = (payload?: ActionsModel[]) =>
    new LoadUnreadActions(payload);
}
