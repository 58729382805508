import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { actionsFilter } from '../../__mocks__/actions-filter-list.mock';
import { ActionsFilterModel } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ActionsFilterService {
  getActionsFilterList(): Observable<ActionsFilterModel[]> {
    // TODO, will Replace this mock data with your actual HTTP call
    const mockData: ActionsFilterModel[] =
      actionsFilter.data.actionsFilter.data;

    return of(mockData);
  }
}
