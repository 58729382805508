import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { actionsListServiceMock } from '../../__mocks__';
import { ActionsModel } from '../../models';

@Injectable({ providedIn: 'root' })
export class ActionsDetailsService {
  getActionsList(
    _category: string,
    _service: string,
    _company: string,
    _site: string,
  ): any {
    // TODO: replace this when BE endpoint is ready
    return of(actionsListServiceMock.actions);

    // TODO: Uncomment the following code when the BE API is ready
    /*
    return this.apollo
      .use(this.actionsClientName)
      .query({
        query: ACTIONS_DETAILS_QUERY,
        variables: {
          category,
          service,
          company,
          site,
        },
      })
      .pipe(
        map((results: any) => results.data.actions),
      );
    */
  }

  updateActions(id: number): Observable<ActionsModel[]> {
    // TODO, will call actual HTTP call after "updated actions" method
    const mockData: ActionsModel[] = actionsListServiceMock.actions;
    const updatedActions = mockData.map((actionItem) =>
      actionItem.id === id
        ? {
            ...actionItem,
            isRead: true,
            iconTooltip: {
              ...actionItem.iconTooltip,
              iconClass: 'read-notification',
            },
          }
        : actionItem,
    );

    return of(updatedActions);
  }
}
